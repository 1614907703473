.Welcome {
    position: fixed;
    min-height: 100%;
    width: 100%;

    h2 {
        font-family: Merriweather;
        font-weight: 400;
        font-size: $reg;
        text-align: center;

        &::selection {
            color: $white;
            background: $red;
        }

        .caret {
            display: inline-block;
            margin-left: -6px;
            color: $red;
            animation: PulseCaret linear 2s;
            animation-iteration-count: infinite;
            opacity: .1;
        }
    }

    .accolades {
        height: 53px;
        background: url(/images/accolades.png) center center no-repeat transparent;
        background-size: 50%;
        position: absolute;
        bottom: 40px;
        width: 100%;
    }

    .helpButton {
        align-self: flex-start;
        font-size: $small;
        opacity: .6;
        display: inline-block;

        &:hover {
            opacity: 1;
        }

        cursor: pointer;
        color: $light-grey;
        text-decoration: none;
        position: absolute;
        top: 20px;
        left: 20px;

        &:before {
            content: '\3f';
            font-family: "mdwa";
            font-style: normal;
            font-weight: normal;
            font-size: 80%;
            speak: none;
            display: inline-block;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            border: 2px solid $light-grey;
            text-align: center;
            vertical-align: middle;
            line-height: 20px;
            margin: -2px 6px 0 0;
        }
    }
    .footer {
        position: fixed;
        right: 10px;
        bottom: 10px;
    }
}

.logo {
    width: 500px;
    margin: 0px auto;
    position: relative;

    @keyframes PulseCaret {
        12% {
            opacity: 1;
        }

        80% {
            opacity: .1;
        }
    }

    h1 {
        width: calc(100% - 172px);
        position: absolute;
        top: 0px;
        margin: 0px;
        padding: 0px;
        left: 172px;
        font-size: $xxlarge;
        font-weight: 900;
        height: 156px;
        line-height: 1em;

        span {
            word-break: nowrap;
            position: absolute;
            display: inline-block;
            width: 100%;
        }

        span:first-child {
            top: -6px;
            color: $red;
        }

        span:nth-child(2) {
            top: 52px;
            color: #51565D;
        }

        span:last-child {
            top: 110px;
            color: $red;
        }
    }

    .mark {
        width: 156px;
        height: 156px;
        background: $red;
        //box-shadow: inset 0px 0px 0px 20px $red, inset 0px 0px 0px 32px $white;
        background: url(/images/logo-squared.png) no-repeat center center;
        background-size: 156px; // this will make sure your image covers the whole div
    }

    &.small {
        width: 300px;
        margin: 24px auto 60px auto;

        h1 {
            width: 270px;
            font-size: $small;
            font-weight: 400;
            left: 30px;
            height: 24px;

            span {
                position: static;
                display: inline;
                line-height: 24px;
                margin-right: 4px;
            }
        }

        .mark {
            width: 24px;
            height: 24px;
            box-shadow: inset 0px 0px 0px 3px $red, inset 0px 0px 0px 5px $white;
        }

        .caret {
            display: none;
        }
    }
}
